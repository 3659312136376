import classNames from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import Countdown from "react-countdown-now";

import AuthWrapper from "@/components/auth-wrapper";
import { LEAD_FLOW_CTA_SOURCE } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { ROUTES } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { renderMarkdown } from "@/utils/helpers/markdown";

import styles from "./NotificationBanner.module.scss";

const formatHeadlineCopy = (headline) => {
  const headlineArr = headline.split(" ");
  const joinLastTwoElementsWithSpace = [
    headlineArr[headlineArr.length - 2],
    headlineArr[headlineArr.length - 1],
  ].join(`\xa0`);
  headlineArr[headlineArr.length - 2] = joinLastTwoElementsWithSpace;
  headlineArr.pop();
  const formattedHeadline = headlineArr.join(" ");

  return formattedHeadline;
};

const NotificationBanner = ({ data, sys, club }) => {
  const countDownActive = true;
  const { setLeadFlowInfo } = useContext(DigitalDataLayerContext);

  const handleClick = (leadFlow) => {
    setLeadFlowInfo({
      club,
      ctaEvent: `${data.disclaimerCopy} | ${LEAD_FLOW_CTA_SOURCE.PROMO}`,
      data: leadFlow.fields,
      isEmbedded: false,
    });
  };

  const renderInteraction = (data) => {
    const { leadFlow, url, disclaimerCopy, textColor } = data;

    if (leadFlow) {
      return (
        <AuthWrapper renderOnUserSession={false}>
          <Link href={ROUTES.NEW_LEAD_FLOW_ROUTE}>
            <a
              className={`${styles.notificationCopy} ${styles.notificationCopyLink}`}
              onClick={() => handleClick(leadFlow)}
              role="button"
              style={textColor && { color: textColor }}
            >
              {disclaimerCopy}
            </a>
          </Link>
        </AuthWrapper>
      );
    }

    return (
      <a
        className={`${styles.notificationCopy} ${styles.notificationCopyLink}`}
        href={url}
      >
        <span style={textColor && { color: textColor }}>{disclaimerCopy}</span>
      </a>
    );
  };

  const getNotificationBanner = (notificationBanner) => {
    if (notificationBanner.sys) {
      const isCampaign =
        notificationBanner.sys.contentType.sys.id === "moduleCampaignBanner";

      if (isCampaign) {
        // This check accounts for all notificationBannerCampaigns set as draft
        const { notificationBannerCampaigns } = notificationBanner.fields;
        const publishedBanner =
          notificationBannerCampaigns &&
          notificationBannerCampaigns.length > 0 &&
          notificationBannerCampaigns[0].fields;

        return publishedBanner ? publishedBanner : false;
      }

      return notificationBanner.fields;
    }

    // @TODO handling UI modules with turnary - when  depricated return just false
    return notificationBanner.sys ? false : notificationBanner;
  };

  const notifBanner = getNotificationBanner(data, sys);
  const {
    headline,
    backgroundColor,
    textColor,
    countdownTimer,
    countdownTimerText,
  } = notifBanner;

  if (!notifBanner) {
    return <div />;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>0D 0H 0M 0S</span>;
    }

    return (
      <span>
        {days}D {hours}H {minutes}M {seconds}S
      </span>
    );
  };

  return (
    <aside
      className={classNames(styles.notificationBanner, "notification-banner")}
      style={textColor && { color: textColor }}
    >
      <div
        className={`${styles.notificationBannerInner} navigation-height`}
        style={backgroundColor && { backgroundColor }}
      >
        <span
          className={styles.notificationCopy}
          style={textColor && { color: textColor }}
        >
          {" "}
          {renderMarkdown(formatHeadlineCopy(headline))}
          &nbsp;
          {countdownTimer && countDownActive && (
            <span>
              {countdownTimerText}&nbsp;
              <Countdown date={countdownTimer} renderer={renderer} />.
            </span>
          )}
        </span>
        {renderInteraction(notifBanner)}
      </div>
    </aside>
  );
};

NotificationBanner.propTypes = {
  club: PropTypes.object,
  data: PropTypes.object.isRequired,
  sys: PropTypes.object,
};

export default NotificationBanner;
